<template>
  <div>
    <PageTitle
      :items="{
        title: 'コラム',
        subTitle: '新規作成',
        back: {
          action: () => {
            $router.push({
              name: 'Media'
            })
          }
        }
      }"
    >
    </PageTitle>

    <validation-observer ref="observer">
      <v-form @submit.prevent="">
        <v-row>
          <v-col cols="8">
            <ColumnInformation
              :sectionTitle="false"
              :basicInformation="columnInformation"
            />
          </v-col>
          <v-col cols="4">
            <v-card height="264px" class="text-center pt-14">
              <v-btn
                @click="openDraftPopup()"
                outlined
                color="#13ABA3"
                min-width="150px"
                width="259px"
                >下書き保存</v-btn
              >
              <v-btn
                @click="submitMediaPost('P')"
                outlined
                class="mt-6"
                color="#13ABA3"
                min-width="150px"
                width="259px"
                >プレビュー</v-btn
              >
              <v-btn
                @click="submitMediaPost()"
                color="#13ABA3"
                class="white--text mt-6"
                min-width="150px"
                width="259px"
                >公開</v-btn
              >
            </v-card>
            <ImageUpload :data="imageDetails" />
            <ColumnTag :mediaTag="mediaTags" />
          </v-col>
        </v-row>
      </v-form>
    </validation-observer>

    <SimpleModel
      text="このコラムを下書きとして保存しますか？"
      :dialog="dialog.saveAsDraft"
      @submitSuccess="saveAsDraft()"
      @closeModel="dialog.saveAsDraft = false"
    ></SimpleModel>
  </div>
</template>

<script>
import ColumnInformation from '@/components/pages/PostInputs'
import ImageUpload from '@/components/ui/ImageUpload.vue'
import ColumnTag from '@/components/pages/column/ColumnTag.vue'
import SimpleModel from '@/components/models/SimpleModel'
import MediaMixin from './media.mixin'
import { mapGetters } from 'vuex'

export default {
  name: 'ColumnCreate',
  components: {
    ColumnInformation,
    ColumnTag,
    ImageUpload,
    SimpleModel
  },
  mixins: [MediaMixin],
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['getPreviewPageURL']),
  },
  created() {
    this.getPageFields()
  },
  methods: {
    submitMediaPost(isDraftCheck = 'N') {
      let action = this.saveAction
      this.isDraftOrPublic = '1' // draft post
      if (isDraftCheck == 'N') {
        action = this.saveAction
        this.isDraftOrPublic = '0' // public post
      } else {
        if (isDraftCheck == 'P') {
          action = result => {
            this.isSaved = result.data.data.data.id
            this.$store.commit('UPDATE_PREVIEW_URL', {
              preview_url: `post-detail/${result.data.data.data.seo_slug}?preview=true`
            })
            window.open(this.getPreviewPageURL, '_blank')
          }
        }
      }

      this.$nextTick(function() {
        this.savePageData('create', action) // Mixins function
        this.dialog.saveAsDraft = false
      })
    }
  }
}
</script>
