var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PageTitle',{attrs:{"items":{
      title: 'コラム',
      subTitle: '新規作成',
      back: {
        action: function () {
          _vm.$router.push({
            name: 'Media'
          })
        }
      }
    }}}),_c('validation-observer',{ref:"observer"},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('ColumnInformation',{attrs:{"sectionTitle":false,"basicInformation":_vm.columnInformation}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-card',{staticClass:"text-center pt-14",attrs:{"height":"264px"}},[_c('v-btn',{attrs:{"outlined":"","color":"#13ABA3","min-width":"150px","width":"259px"},on:{"click":function($event){return _vm.openDraftPopup()}}},[_vm._v("下書き保存")]),_c('v-btn',{staticClass:"mt-6",attrs:{"outlined":"","color":"#13ABA3","min-width":"150px","width":"259px"},on:{"click":function($event){return _vm.submitMediaPost('P')}}},[_vm._v("プレビュー")]),_c('v-btn',{staticClass:"white--text mt-6",attrs:{"color":"#13ABA3","min-width":"150px","width":"259px"},on:{"click":function($event){return _vm.submitMediaPost()}}},[_vm._v("公開")])],1),_c('ImageUpload',{attrs:{"data":_vm.imageDetails}}),_c('ColumnTag',{attrs:{"mediaTag":_vm.mediaTags}})],1)],1)],1)],1),_c('SimpleModel',{attrs:{"text":"このコラムを下書きとして保存しますか？","dialog":_vm.dialog.saveAsDraft},on:{"submitSuccess":function($event){return _vm.saveAsDraft()},"closeModel":function($event){_vm.dialog.saveAsDraft = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }